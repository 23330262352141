<template>
  <view-container scroll-fab>
    <div class="pa-5">
      <v-row v-if="loading">
        <v-col cols="12" xl="3" lg="4" md="6" sm="12" v-for="i in [1,2,3,4,5]" :key="i">
          <v-skeleton-loader
            v-bind="attrs"
            type="avatar, actions"
            class="shadow pa-2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12" xl="3" lg="4" md="6" sm="12" v-for="(store, i) in getStores" :key="i">
          <store-card :store="store" :address="getAddress" />
        </v-col>
      </v-row>
    </div>
  </view-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import StoreCard from "./StoreCard";

export default {
  name: "Stores",
  components: { ViewContainer, StoreCard },
  mounted() {
    const { regionId } = this.$getUserInfo();
    this.loadStoresByParentId(regionId).then(() => (this.loading = false));

    this.setReactivityParams([
      { resource: "STORES", scope: "store", key: regionId },
    ]);

    this.$sub("reactivity/STORES", (payload) => {
      console.log("reactivity/STORES:", payload);
      this.loading = true;
      this.reloadStoresByParams({
        regionId,
        storeIds: [payload.store],
      }).then(() => (this.loading = false));
      console.log("reactivity/STORES:", payload);
    });
  },
  beforeDestroy() {
    this.$unsubAll();
    this.clearReactivityParams();
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("stores", ["getStoresByParentId"]),

    getStores() {
      const { regionId } = this.$getUserInfo();
      return this.getStoresByParentId(regionId);
    },
    getAddress() {
      const { activeAddress } = this.$getUserInfo();
      return activeAddress;
    },
  },
  methods: {
    ...mapActions("stores", ["loadStoresByParentId", "reloadStoresByParams"]),
    ...mapActions("ui", ["setReactivityParams", "clearReactivityParams"]),

    getNameInit(name) {
      if (!name) {
        return "mdi-circle";
      }

      return `mdi-alpha-${name.substring(0, 1).toLowerCase()}-circle`;
    },
  },
};
</script>
